/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// libraries
require('jquery-match-height');
require('../../node_modules/magnific-popup/dist/jquery.magnific-popup.js');
require('owl.carousel');
require('../../node_modules/waypoints/lib/jquery.waypoints.js');
require('../../node_modules/counterup/jquery.counterup.js');
require('jquery-validation');
require('../../node_modules/jquery-validation/dist/additional-methods.js');
// require('../../node_modules/particles.js/particles.js');
// require('../../node_modules/particles.js/demo/js/app.js');

require('../../public/js/custom.js');
